import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/homepage/hero"
import PortalCTA from "../components/homepage/PortalCTA"
import TextWithWatermark from "../components/elements/TextWithWatermark"
import PrimaryCTA from "../components/elements/PrimaryCTA"
import TextCTA from "../components/homepage/TextCTA"
import Announcements from "../components/elements/Announcements"
import SectionAbout from "../components/homepage/section-about"
import LinkCard from "../components/elements/LinkCard"
import Testimonials from "../components/elements/Testimonials"

export default function Homepage(props) {
  const { homepage } = props.data

  return (
    <Layout
      {...homepage}
      seo={{
        title: "American Shipping International - Smarter Freight Forwarding",
        metaDesc:
          "ASI joins hands with trustworthy and professional carriers and vendors to negotiate the best rates, and deliver the optimal mix of services",
      }}
    >
      <Hero />
      <TextWithWatermark
        title="We Help Individuals and Companies"
        button={{ label: "Discover Our Services", to: "/services" }}
        className="pb-32"
        mainTitle="What we do"
        bottomComponent={
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 ">
            <LinkCard title="What We Do" link="/what-we-do">
              Warehousing and storage, Consolidation and deconsolidation,
              Insurance and Customs compliance
            </LinkCard>
            <LinkCard title="How We Ship" link="/how-we-ship">
              Air, Land, and Sea Transport
            </LinkCard>
            <LinkCard title="What We Ship" link="/what-we-ship">
              Container, Breakbulk, Project Cargo, General Cargo, and Special
              Cargo
            </LinkCard>
          </div>
        }
      >
        Through our accumulated knowledge and expertise, we support businesses
        and individuals by advising them on the best strategy for integrating
        in-demand freight services using advanced digital tools.
      </TextWithWatermark>

      <PortalCTA hideWatermark />

      <SectionAbout
        title="American Shipping International in a Nutshell"
        button={{ label: "Get to Know More About ASI", to: "/about-us" }}
        mainTitle="About Us"
      >
        Putting our customer’s satisfaction first, ASI joins hands with
        trustworthy and professional carriers and vendors to negotiate the best
        rates, and deliver the optimal mix of services. With so many options out
        there, we simplify the shipping process from A-Z; saving you a
        substantial amount of time and effort.
      </SectionAbout>

      <TextCTA
        title="We are ASI"
        line1="Delivering Quality Freight"
        line2="Solutions with Exceptional Expertise"
      />

      <Testimonials />

      <Announcements />
      <PrimaryCTA
        title="We Can Lend a Hand"
        subtitle="Let our experts manage the details of your shipping and logistics needs, so you don’t have to!"
        button={{ label: "Reach Out", to: "/contact" }}
      />
    </Layout>
  )
}

export const query = graphql`
  {
    homepage {
      id
      title
      description
      image {
        id
        url
      }
      # blocks: content {
      #   id
      #   blocktype
      #   ...HomepageHeroContent
      #   ...HomepageFeatureListContent
      #   ...HomepageCtaContent
      #   ...HomepageLogoListContent
      #   ...HomepageTestimonialListContent
      #   ...HomepageBenefitListContent
      #   ...HomepageStatListContent
      #   ...HomepageProductListContent
      # }
    }
  }
`
