import React, { useState } from "react"
import { classNames } from "../../helpers"
import LabelText from "./LabelText"
import Stars from "./Stars"

export default function Testimonials() {
  const [active, setActive] = useState(0)
  return (
    <div className="testimonials px-0 md:px-28 md:pr-0 flex flex-col md:flex-row items-center divide-x-none md:divide-x divide-[#0c2352] border-b border-b-[#0c2352]">
      <div className="tblock py-20 px-8 md:px-0 w-full md:w-2/5 h-full overflow-hidden relative !flex flex-col">
        <h2 className="tracking-wider uppercase mb-8">
          <LabelText>Testimonials</LabelText>
        </h2>
        <h2 className="text-3xl  text-black font-extrabold tracking-tight sm:text-[48px] leading-[1.1] max-w-md sm:max-w-3xl">
          <span className="block">Our Clients'</span>{" "}
          <span className="block">Experiences</span>
        </h2>
	
      </div>

      <div className="tblock h-full w-full md:w-3/5 py-10 bg-[#ecf2ff] !flex flex-col relative overflow-hidden">
      <Stars
        className="absolute hidden md:block top-[-50px] left-[-50px] z-0 opacity-50"
        width={450}
        fill="#dbe2f1"
      />
        {testimonials.map((testimonial, index) => (
          <blockquote
            className={classNames(
              "px-8 py-12 min-h-[438px] relative z-10",
              active == index ? "visible" : "invisible hidden"
            )}
          >
            <p className="leading-relaxed ">{testimonial.content}</p>
            <footer>
              <cite>
                <h2 className="tracking-wider uppercase text-secondary">
                  <LabelText>{testimonial.name}</LabelText>
                </h2>
              </cite>
            </footer>
          </blockquote>
        ))}

        <div className="flex items-center justify-center gap-2 px-8 pb-12">
          {testimonials.map((testimonial, index) => (
            <span
				onClick={() => setActive(index)}
              className={classNames(
                "inline-block rounded-full cursor-pointer transition-all h-2 w-2",
                active == index
                  ? "bg-secondary scale-[2] mx-2"
                  : "bg-secondary bg-opacity-20 hover:bg-secondary hover:bg-opacity-40 hover:scale-110 "
              )}
            ></span>
          ))}
        </div>
      </div>
    </div>
  )
}

const testimonials = [
  {
    content: `“5 star rating easily! I’ve been unlucky to use a few shippers for
		our clients across multiple locations in the past and they’ve all
		been mostly slow to pick up, expensive or too busy to even
		communicate professionally. ASI Shipping on the other hand is the
		total opposite. They provide prompt pickup and their rates are very
		competitive. I doubt if any other shipper can match their rates and
		at that their services are way better. They really put their
		customers first and are quick to assist with alternative options to
		help you save on non runners or forklift fees where possible. I have
		no reservations recommending them and I hope they will continue to
		maintain their excellent service delivery.”`,
    name: "Femi",
  },
  {
    content: `“American Shipping International is simply the best in the industry.
		If you want to grow and maximize your profit, just ask Chris and his
		staff. They know what they are doing. They make it easy to sleep at
		night knowing my business is in the right hands.”`,
    name: "BODAP",
  },
  {
    content: `“I definitely recommend ASI shipping. I have been using this
		company for the past few years and they always deliver exceptional
		service, very friendly staff and amazing customer service. I highly
		recommend them.”`,
    name: "Demola",
  },
]
