import { withPrefix } from "gatsby"
import React from "react"
import { classNames } from "../../helpers"
import Button from "../elements/Button"
import LabelText from "../elements/LabelText"
import {
  ArrowNarrowRightIcon,
  BookmarkIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  DeviceMobileIcon,
  GlobeAltIcon,
  LocationMarkerIcon,
} from "@heroicons/react/solid"

const services = [
  { title: "Tracking", link: "https://portal.asishipping.com/tracking", icon: <LocationMarkerIcon className="w-6 h-6 text-white" /> },
  {
    title: "Schedules",
    link: "https://portal.asishipping.com/schedules",
    icon: <CalendarIcon className="w-6 h-6 text-white" />,
  },
  {
    title: "Quotation",
    link: "/get-a-quote",
    icon: <CurrencyDollarIcon className="w-6 h-6 text-white" />,
  },
  {
    title: "Booking",
    link: "https://portal.asishipping.com/bookings",
    icon: <BookmarkIcon className="w-6 h-6 text-white" />,
  },
  // { title: "Client Portal", link: "https://portal.asishipping.com/", icon: <GlobeAltIcon className="w-6 h-6"/> },
  // { title: "Mobile App", link: "/by-innovation-and-digitization", icon: <DeviceMobileIcon className="w-6 h-6"/> },
]

export default function PortalCTA({ hideServices = false, hideWatermark }) {
  return (
    <div
      className="flex flex-col md:flex-row lg:items-center bg-secondary-700"
      style={{
        background: `url(${withPrefix("/images/globe.jpg")})`,
        backgroundPosition: "top",
        backgroundAttachment: "fixed",
        backgroundSize: 'cover'
      }}
    >
      <div className="w-full md:w-[65%] ">
        <div className=" py-12 sm:py-16 lg:py-20 px-8 md:px-28">
          <h2 className="tracking-wider uppercase mb-8">
            <LabelText light>Digital Services</LabelText>
          </h2>
          <h3 className="text-3xl text-white font-extrabold tracking-tight sm:text-[48px] leading-[1.5]">
          <span className="block">The Inevitable Change.</span> <span className="block">Proud to Be Part of The Digital Era. </span>
          </h3>
          <div className="mt-6 text-white space-y-6">
            <p className="text-[18px] md:text-[24px] font-light leading-relaxed max-w-2xl ">
            We are on an ongoing quest to find and adopt the latest technological tools that’ll elevate any shipping request to the next level; in particular a hassle-free one. So far, we’ve equipped our clients with e-tools such as browsing shipping schedules, online booking, downloading shipping documents and invoices, tracking and much more. 
            </p>
          </div>
          <div className="mt-12">
            <Button link="https://portal.asishipping.com" label="Go to Client Portal" />
          </div>
        </div>

        {/* <div className="h-[23vh] border-t border-white px-28">
          <div className="flex items-center flex-col py-2">
            <div className="text-white mt-4 font-medium">Tracking</div>
          </div>
        </div> */}
      </div>

      <div className="w-full md:w-[35%] md:border-l border-white flex flex-col divide-y divide-white">
        {services.map((item) => (
          <a key={item.title} href={item.link} className="backdrop-blur-md hover:bg-primary hover:bg-opacity-60 hover:backdrop-blur-xl transition-colors hover:scale " target="_blank">
            <div className="flex px-8 py-8 md:py-20 flex-row items-center">
              <div className="bg-secondary-600 backdrop-blur-md bg-opacity-80 rounded-sm w-12 h-12 inline-flex items-center justify-center">
                {item.icon}
              </div>
              <div className="text-white ml-8 text-xl md:text-3xl font-semibold flex-1">{item.title}</div>
              <ArrowNarrowRightIcon className="h-[20px] text-white" />
            </div>
          </a>
        ))}
      </div>
    </div>
  )
}
