import { ArrowNarrowRightIcon } from "@heroicons/react/solid"
import { withPrefix } from "gatsby"
import React, { useEffect, useRef } from "react"
import Button from "../elements/Button"
import { Container } from "../ui"
import Stars from "./../../assets/images/flag.svg"

export default function Hero() {
 
  return (
    <div
      className="items-center bg-white h-[80vh] !bg-cover !bg-center"
      style={{
        background: `url(${withPrefix("/images/terminal.jpg")})`,
      }}
    >
      <div className="relative  h-[80vh] overflow-hidden ">
        {/* <video
          //width="100%"
          height="100%"
          autoPlay
          muted
          loop
          className="absolute z-0 right-0 left-0 top-0 bottom-0 object-cover min-w-full min-h-full hidden md:block"
          
        >
          <source
            src="https://cms.asishipping.com/wp-content/uploads/2022/11/video-comp.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video> */}
        <div className="bg-[rgba(13,18,26,0.6)] h-full z-10 absolute left-0 right-0"></div>
        <div className="relative z-20">
          <Container>
            <div className="h-[80vh] flex justify-center items-center flex-col ">
              <h1 className="text-3xl md:text-7xl text-white font-semibold uppercase leading-[1.2] tracking-tight mb-6 text-center">
                <span className="block ">Simpler & Smarter</span>
                <span className="block">
                  <span className=" text-primary-500">Freight</span> Forwarding
                </span>
              </h1>
              <p className="text-2xl max-w-xl text-center text-white text-opacity-90">
                ASI offers smart freight products and services that challenges
                traditional freight forwarders
              </p>
              <span className="mt-8">
                <Button link="/get-a-quote" label="Request a Quote" />
              </span>
            </div>
          </Container>
          {/* <Stars /> */}
          {/* <div className="flag-overlay"></div> */}
        </div>
      </div>
    </div>
  )
}
