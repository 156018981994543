import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import CenteredTextBlock from "./CenteredTextBlock"
import LinkCard from "./LinkCard"

const posts = [
  {
    title:
      "Grimaldi Terminal Update: Jacksonville - Shut Down Forklift Receiving.",
    href: "#",
    description:
      "Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.",
    date: "Mar 16, 2020",
    datetime: "2020-03-16",
  },
  {
    title:
      "Grimaldi WAF Direct Service Announcement: Jax - Forklift Receiving Update",
    href: "#",
    description:
      "Optio cum necessitatibus dolor voluptatum provident commodi et. Qui aperiam fugiat nemo cumque.",
    date: "Mar 10, 2020",
    datetime: "2020-03-10",
  },
  {
    title: "Grimaldi - Jacksonville - Resumes Forklift & Non Runner Receiving",
    href: "#",
    description:
      "Cupiditate maiores ullam eveniet adipisci in doloribus nulla minus. Voluptas iusto libero adipisci rem et corporis.",
    date: "Feb 12, 2020",
    datetime: "2020-02-12",
  },
  {
    title: "Sallaum Terminal Status Update For Tuesday - July 5th, 2022",
    href: "#",
    description:
      "Ipsum voluptates quia doloremque culpa qui eius. Id qui id officia molestias quaerat deleniti. Qui facere numquam autem libero quae cupiditate asperiores vitae cupiditate. Cumque id deleniti explicabo.",
    date: "Jan 29, 2020",
    datetime: "2020-01-29",
  },
]

export default function Announcements() {
  const data = useStaticQuery(graphql`
    {
      allMailchimpCampaign(limit: 30) {
        nodes {
          campaignId
          id
          settings {
            title
            subject_line
          }
          send_time
          status
        }
      }
    }
  `)
  const news = data.allMailchimpCampaign.nodes.slice(0, 6).map((node) => ({
    title: node.settings.title,
    href: `/news/`,
    description: node.settings.subject_line,
    date: new Date(node.send_time).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }),
    datetime: node.send_time,
  }))

  // get only the first 3 items of the array

  return (
    <CenteredTextBlock
      mainTitle="News & Announcements"
      title="What we have been up to"
      button={{label: "Subscribe", to: "http://eepurl.com/hPvobX"}}
      hideBackPattern
      bottomComponent={
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:px-28 pt-28">
          {news.map((node) => (
            <LinkCard title={node.title} mainTitle={node.date}  />
          ))}
        </div>
      }
    >
      
    </CenteredTextBlock>
    // <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
    //   <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
    //     <div>
    //       <h2 className="text-3xl tracking-tight font-extrabold text-secondary sm:text-4xl">
    //   Announcements
    //       </h2>
    //       <div className="mt-0 sm:mt-2 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
    //         <p className="text-xl text-gray-500">
    // 		Check out what American Shipping International has been up to
    //         </p>
    //         <form className="mt-6 flex flex-col sm:flex-row lg:mt-0 lg:justify-end">
    //           <div>
    //             <label htmlFor="email-address" className="sr-only">
    //               Email address
    //             </label>
    //             <input
    //               id="email-address"
    //               name="email-address"
    //               type="email"
    //               autoComplete="email"
    //               required
    //               className="appearance-none w-full px-4 py-2 border border-gray-300 text-base rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:ring-primary-500 focus:border-primary-500 lg:max-w-xs"
    //               placeholder="Enter your email"
    //             />
    //           </div>
    //           <div className="mt-2 flex-shrink-0 w-full flex rounded-md shadow-sm sm:mt-0 sm:ml-3 sm:w-auto sm:inline-flex">
    //             <button
    //               type="button"
    //               className="w-full bg-primary px-4 py-2 border border-transparent rounded-md flex items-center justify-center text-base font-medium text-white hover:bg-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto sm:inline-flex"
    //             >
    //               Subscribe
    //             </button>
    //           </div>
    //         </form>
    //       </div>
    //     </div>
    //     <div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
    //       {posts.map((post) => (
    //         <div key={post.title}>
    //           <p className="text-sm text-gray-500">
    //             <time dateTime={post.datetime}>{post.date}</time>
    //           </p>
    //           <a href="#" className="mt-2 block">
    //             <p className="text-xl font-semibold text-gray-900">
    //               {post.title}
    //             </p>
    //             {/* <p className="mt-3 text-base text-gray-500">
    //               {post.description}
    //             </p> */}
    //           </a>
    //           <div className="mt-3">
    //             <a
    //               href={post.href}
    //               className="text-base font-semibold text-primary hover:text-primary-400"
    //             >
    //               Read more
    //             </a>
    //           </div>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    // </div>
  )
}
